import { CreateIcon, InfoIcon, SearchIcon } from '@invisible/ui/icons'
import { Text } from '@invisible/ui/text'
import { baseTheme, color, fontSizes, gray, styled, textColor } from '@invisible/ui/themes'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/client'
import { FC, useEffect } from 'react'
import { Flex } from 'rebass'

import BuildProcess from '../../public/BuildProcess.png'
import ExistingProcess from '../../public/ExistingProcess.png'
import logo from '../../public/logo.png'
// import existingProcess from '../public/ExistingProcess.svg'

const Wrapper = styled.div`
  background: white;
  height: 100%;
  width: 100%;
`

const Container = styled.div`
  width: 850px;
  margin: 0 auto;
  padding: 5% 0 2% 0;
  min-height: calc(100vh - 60px);
  box-sizing: border-box;
`

const ImageButton = styled.button`
  width: 380px;
  height: 380px;
  padding: 0;
  background: ${gray(2)};
  border: ${({ theme }) => `1px solid ${theme.grayScale[4]}`};
  border-radius: 6px;
  cursor: pointer;
`

const InfoBox = styled.div`
  font-size: ${fontSizes(0)};
  color: ${textColor('primary')};
  margin: 0 auto;
  margin-top: 50px;
  padding: 10px;
  background: rgba(239, 237, 245, 0.6);
  border-radius: 4px;
  width: 650px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const TextButton = styled.button`
  margin-top: 20px;
  color: ${color('primary')};
  font-size: ${fontSizes(2)};
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 5px;

  &:hover {
    background: ${gray(3)};
  }
  svg {
    margin-right: 10px;
  }
`

export const Landing: FC = () => {
  const [session] = useSession()
  const router = useRouter()

  useEffect(() => {
    router.prefetch('/process/search')
    router.prefetch('/process/build')
  }, [router])

  return (
    <Wrapper>
      <Container>
        <Text fontSize='26px' color={baseTheme.textColor.primary} fontWeight='300'>
          Hey {session?.user?.name?.split(' ')[0]}! Welcome to the Process Library
        </Text>
        <Text fontSize='20px' color={baseTheme.textColor.title} fontWeight='bold' my='20px'>
          What do you want to do today?
        </Text>
        <Flex justifyContent='space-between' mt='40px'>
          <Flex flexDirection='column' alignItems='center'>
            <ImageButton onClick={() => router.push('/process/search')}>
              <Image
                loading='eager'
                src={ExistingProcess}
                alt='ExistingProcess'
                width={380}
                height={380}
              />
            </ImageButton>
            <TextButton onClick={() => router.push('/process/search')}>
              <SearchIcon color={baseTheme.colors.primary} /> Find an existing process
            </TextButton>
          </Flex>

          <Flex flexDirection='column' alignItems='center'>
            <ImageButton onClick={() => router.push('/process/build')}>
              <Image
                loading='eager'
                src={BuildProcess}
                alt='BuildProcess'
                width={380}
                height={380}
              />
            </ImageButton>
            <TextButton onClick={() => router.push('/process/build')}>
              <CreateIcon color={baseTheme.colors.primary} /> Build a process
            </TextButton>
          </Flex>
        </Flex>

        <InfoBox>
          <InfoIcon width={14} height={14} color={baseTheme.colors.primary} />
          We strongly recommend taking a close look at the existing library and reusing a process
          instead of creating a new one
        </InfoBox>
      </Container>
    </Wrapper>
  )
}
