import { withAuth } from '@invisible/authentication/client'
import { GraphqlQueryProvider } from '@invisible/common/providers'

import { Landing } from '../views/processDiscovery'

const LandingPage = withAuth(() => (
  <GraphqlQueryProvider>
    <Landing />
  </GraphqlQueryProvider>
))

export default LandingPage
